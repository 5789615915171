<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import c3api from "@/c3api";
import { onBeforeMount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router/auto";
import { useToast } from "vue-toastification";
import { shortDateWithTimeTz } from "@/utils/dateUtils";
import {
  formatCurrency,
  mapWorkflowStatus,
  agGridformatCurrency,
} from "@/utils/dataUtils";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import OrderBillDetailRenderer from "@/components/OrderBillDetailRenderer.vue";
import OrderBillDeleteCellRenderer from "@/components/billings/OrderBillDeleteCellRenderer.vue";
import { addPrefixToId } from "@/utils/agGridUtils";
import BillingOrderActivitiesPopup from "../../../components/billings/BillingOrderActivitiesPopUp.vue";
import { useThemeStore } from "@/stores/themeStore";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const route = useRoute();
const toast = useToast();

const invoiceOrderBills = ref([]);
const invoiceDetails = ref({});

const DRAFT_STATE = "draft";

let dialog = ref(false);
let gridParams = {};

let context;
let gridApi;
let disableSubmit = ref(false);
const onGridReady = (params) => {
  gridApi = params.api;
  context = {
    onBillSaveCallback: () => {
      getInvoice(route.params.id);
      orderBillsForInvoice(route.params.id);
    },
    disableSubmit,
  };
};

const orderBillsForInvoice = async (invoiceId) => {
  const response = await c3api.get(`/order_invoices/${invoiceId}/order_bills`);
  invoiceOrderBills.value = response.data.data;
};

const onInvoiceDeleteCallback = async (invoiceId) => {
  const deleteUrl = `/order_invoices/${invoiceDetails.value.id}/modify_order_bills`;
  const payLoad = {
    action: "remove",
    order_bill_ids: [params.params.data?.id],
  };

  await c3api
    .patch(deleteUrl, payLoad)
    .then((reponse) => {
      toast.success("The order bill has been successfully deleted.");
      // Refetch the invoice for status update.
      orderBillsForInvoice(route.params.id);
    })
    .catch((error) => {
      toast.error(error.response?.data.data.join(". "));
    });
};

const getInvoice = async (invoiceId) => {
  const invoiceDetail = await c3api.get(`/order_invoices/${invoiceId}`);
  invoiceDetails.value = invoiceDetail.data.data;
};

const detailCellRenderer = ref(null);
const submitInvoice = async (sendToQuickbooks = true) => {
  const completeUrl = `/order_invoices/${route.params.id}/complete`;
  const params = {
    send_to_quickbooks: sendToQuickbooks,
  };
  await c3api
    .post(completeUrl, params)
    .then((reponse) => {
      toast.success(
        sendToQuickbooks
          ? "The invoice has been successfully added to QuickBooks."
          : "The invoice has been marked as complete."
      );
      // Refetch the invoice for status update.
      getInvoice(route.params.id);
      orderBillsForInvoice(route.params.id);
    })
    .catch((error) => {
      toast.error(error.response?.data.data.join(". "));
    });
};

// New ref for confirmation dialog
const showConfirmDialog = ref(false);

// New method to open confirmation dialog
const openConfirmDialog = () => {
  showConfirmDialog.value = true;
};

// New method to handle confirmation dialog actions
const handleConfirmAction = (action) => {
  showConfirmDialog.value = false;
  if (action === "sendToQuickbooks") {
    submitInvoice(true);
  } else if (action === "markComplete") {
    submitInvoice(false);
  }
  // If action is 'cancel', we just close the dialog
};

const onBillDeleteCallback = async () => {
  orderBillsForInvoice(route.params.id);
  getInvoice(route.params.id);
};

const colDefs = ref([
  {
    field: "id",
    headerName: "Bill #",
    cellDataType: "text",
    width: 135,
    cellRenderer: "agGroupCellRenderer",
  },
  {
    field: "order.id",
    headerName: "Order #",
    cellDataType: "text",
    width: 135,
    valueFormatter: (params) => {
      return addPrefixToId("B", params.data?.id);
    },
  },
  {
    field: "po_number",
    headerName: "PO #",
    cellDataType: "text",
    width: 135,
  },
  {
    field: "billable_amount",
    headerName: "Amount",
    cellDataType: "text",
    type: "rightAligned",
    width: 135,
    valueFormatter: agGridformatCurrency,
  },
  {
    field: "action",
    headerName: "",
    cellRenderer: OrderBillDeleteCellRenderer,
    cellRendererParams: {
      onBillDeleteCallback: onBillDeleteCallback,
    },
    width: 135,
  },
]);

// Simulate a condition that determines whether the column should be hidden
const shouldHideColumn = () => {
  return invoiceDetails.value.workflow_state !== DRAFT_STATE;
};

// Update column definitions based on condition
const updateColumnDefs = () => {
  colDefs.value.forEach((colDef) => {
    if (colDef.field === "action") {
      colDef.hide = shouldHideColumn();
    }
  });
  gridApi.setGridOption("columnDefs", colDefs.value);
};

watch(invoiceDetails, (invoiceDetails) => {
  updateColumnDefs();
});

const gridOptions = {
  defaultColDef: {
    flex: 1,
  },
};

onBeforeMount(() => {
  detailCellRenderer.value = OrderBillDetailRenderer;
});

orderBillsForInvoice(route.params.id);
getInvoice(route.params.id);

const toggleDialog = (toggleVal) => {
  dialog.value = toggleVal;
  return dialog;
};

/**
 * When the row is double clicked, open the billing activities popup.
 * @param {*} event
 */
const onRowDoubleClicked = (event) => {
  gridParams = event;
  gridParams.dialog = toggleDialog(true);
  gridParams.toggleDialog = toggleDialog;
};
</script>

<template>
  <div v-if="dialog" class="text-center pa-4">
    <BillingOrderActivitiesPopup :params="gridParams" />
  </div>

  <div class="d-flex align-center justify-space-between">
    <div class="d-flex align-center">
      <div>
        <v-card-title class="d-flex align-center pb-0 text-h4">
          Invoice-<span>{{ invoiceDetails.id }}</span>
        </v-card-title>
      </div>
    </div>
    <v-btn
      class="mr-4"
      color="success"
      :disabled="invoiceDetails.workflow_state != DRAFT_STATE || disableSubmit"
      @click="openConfirmDialog"
      >Complete Invoice</v-btn
    >
  </div>

  <div class="invoice-details d-flex mt-2">
    <v-card class="details mr-2 w-50">
      <v-card-text class="detail-card">
        <span>
          <p class="text-h5 mb-1">Bill To Client</p>
          <p>{{ invoiceDetails.client?.name }}</p>
        </span>

        <span>
          <p class="text-h5 mb-1">Total Invoice Amount</p>
          <p>{{ formatCurrency(invoiceDetails.invoice_total) }}</p>
        </span>

        <span>
          <p class="text-h5 mb-1">Status</p>
          <p>{{ mapWorkflowStatus(invoiceDetails.workflow_state) }}</p>
        </span>

        <span v-if="invoiceDetails.workflow_state == DRAFT_STATE">
          <p class="text-h5 mb-1">Updated At</p>
          <p>{{ shortDateWithTimeTz(invoiceDetails.updated_at) }}</p>
        </span>

        <span v-if="invoiceDetails.workflow_state != DRAFT_STATE">
          <p class="text-h5 mb-1">Submitted At</p>
          <p>{{ shortDateWithTimeTz(invoiceDetails.completed_at) }}</p>
        </span>
      </v-card-text>
    </v-card>

    <AgGridVue
      :rowData="invoiceOrderBills"
      :columnDefs="colDefs"
      style="width: 100%; height: 100%"
      :class="themeStore.agTheme"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :detailCellRenderer="detailCellRenderer"
      :context="context"
      @rowDoubleClicked="onRowDoubleClicked"
    ></AgGridVue>
  </div>

  <!-- Confirmation Dialog -->
  <v-dialog v-model="showConfirmDialog" max-width="600px">
    <v-card>
      <v-card-title class="text-h5">Confirm Invoice Completion</v-card-title>
      <v-card-text>
        Please choose whether you would like to send this invoice to Quickbooks, or just
        mark it complete.
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn class="float-0" color="error" @click="handleConfirmAction('cancel')"
          >Cancel</v-btn
        >
        <div>
          <v-btn color="primary" @click="handleConfirmAction('markComplete')"
            >Mark Complete</v-btn
          >
          <v-btn color="success" @click="handleConfirmAction('sendToQuickbooks')"
            >Complete & Send to Quickbooks</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.invoice-details .ag-theme-material .ag-icon {
  display: flex !important;
}
</style>

<style scoped>
#billings-orders-wrapper {
  width: 100%;
  height: 100%;
}

.invoice-details {
  display: flex;
  height: 100%;
}

.details {
  flex-basis: 30%;
  padding: 10px;
}

.billing-list {
  flex-basis: 70%;
  height: 100%;
}

.detail-card {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: large;
}

span > h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
