<script setup>
import c3api from "@/c3api";
import { useToast } from "vue-toastification";

const toast = useToast();

const props = defineProps({
  params: Object,
});

const deleteOrderBill = async () => {
  let params = props.params;
  const deleteUrl = `/order_invoices/${params.data.order_invoice_id}/modify_order_bills`;
  const payLoad = {
    action: "remove",
    order_bill_ids: [params.data.id],
  };

  await c3api
    .patch(deleteUrl, payLoad)
    .then((reponse) => {
      toast.success("The order bill has been successfully deleted.");
      params.onBillDeleteCallback();
    })
    .catch((error) => {
      toast.error(error.response?.data.data.join(". "));
    });
};
</script>

<template>
  <v-btn icon="mdi-delete" variant="text" @click="deleteOrderBill()"> </v-btn>
</template>
